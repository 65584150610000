<template>
  <span v-if="mitglieder.length > 0">
    <h2 v-if="titel">{{titel}}</h2>
    <h2 v-if="KV && titel == ''">Mitglieder {{KV == 'DD' ? 'Dresden' : ''}}{{KV == 'L' ? 'Leipzig' : ''}}{{KV == 'CH' ? 'Chemnitz' : ''}}</h2>
    <table>
      <tr class="head">
        <th title="kopieren" v-if="showCopy" rowspan="2">
          <svg id="copySign" ref="copySign" viewBox='0 0 442 442' v-on:click='copy'>
            <polygon points='291,0 51,0 51,332 121,332 121,80 291,80' />
            <polygon points='306,125 306,195 376,195' />
            <polygon points='276,225 276,110 151,110 151,442 391,442 391,225' />
          </svg>
        </th>
        <th v-if="!showCopy"></th>
        <th v-if="Zugriffsrechte() !== 1 && (this.tableFunction === 'getInteressenten' || this.tableFunction === 'getAnträge')" v-on:click="orderBy = 'Antragsdatum'; sortieren()" :class="{ selected: orderBy == 'Antragsdatum', unselected: orderBy != 'Antragsdatum' }">Antragsdatum</th>
        <th>Anrede</th>
        <th v-on:click="orderBy = 'Nachname'; sortieren()" :class="{ selected: orderBy == 'Nachname', unselected: orderBy != 'Nachname' }">Nachname</th>
        <th v-on:click="orderBy = 'Vorname'; sortieren()" :class="{ selected: orderBy == 'Vorname', unselected: orderBy != 'Vorname' }">Vorname</th>
        <th v-if="Zugriffsrechte() !== 1" v-on:click="orderBy = 'Geburtstag'; sortieren()" :class="{ selected: orderBy == 'Geburtstag', unselected: orderBy != 'Geburtstag' }">Geburtsdatum</th>
        <th v-if="Zugriffsrechte() !== 1" v-on:click="orderBy = 'Geburtsdatum'; sortieren()" :class="{ selected: orderBy == 'Geburtsdatum', unselected: orderBy != 'Geburtsdatum' }">Alter</th>
        <th v-if="Zugriffsrechte() !== 1 && (Verband() === 0 || tableFunction == 'getFördermitglieder')" v-on:click="orderBy = 'Kreisverband'; sortieren()" :class="{ selected: orderBy == 'Kreisverband', unselected: orderBy != 'Kreisverband' }">KV</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten' && this.tableFunction !== 'getAnträge'">Mitgliedsnummer</th>
        <th v-if="Zugriffsrechte() !== 1">Adresse</th>
        <th v-if="Zugriffsrechte() !== 1">Telefonnummer</th>
        <th>E-Mail-Adresse</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten' && this.tableFunction !== 'getFördermitglieder'" v-on:click="orderBy = 'Beitragskategorie'; sortieren()" :class="{ selected: orderBy == 'Beitragskategorie', unselected: orderBy != 'Beitragskategorie' }">Beitragskategorie</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'" v-on:click="orderBy = 'Mitgliedsbeitrag'; sortieren()" :class="{ selected: orderBy == 'Mitgliedsbeitrag', unselected: orderBy != 'Mitgliedsbeitrag' }">Mitgliedsbeitrag</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">Zahlungsintervall</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'" v-on:click="orderBy = 'bezahltAm'; sortieren()" :class="{ selected: orderBy == 'bezahltAm', unselected: orderBy != 'bezahltAm' }">bezahlt am</th>
        <th v-if="Zugriffsrechte() === 0 && (this.tableFunction === 'getMitglieder' || this.tableFunction === 'getFördermitglieder')"></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'" v-on:click="orderBy = 'bezahltBis'; sortieren()" :class="{ selected: orderBy == 'bezahltBis', unselected: orderBy != 'bezahltBis' }">bezahlt bis</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">Lastschrift</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">Datum Lastschriftmandat</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">Kontoinhaber</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">IBAN</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">BIC</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">Kreditinstitut</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'" v-on:click="orderBy = 'Eintrittsdatum'; sortieren()" :class="{ selected: orderBy == 'Eintrittsdatum', unselected: orderBy != 'Eintrittsdatum' }">Eintrittsdatum</th>
        <th v-if="showAustritt && this.tableFunction !== 'getInteressenten'" v-on:click="orderBy = 'Austrittsdatum'; sortieren()" :class="{ selected: orderBy == 'Austrittsdatum', unselected: orderBy != 'Austrittsdatum' }">Austrittsdatum</th>
        <th v-if="Zugriffsrechte() !== 1" v-on:click="orderBy = 'Bemerkungen'; sortieren()" :class="{ selected: orderBy == 'Bemerkungen', unselected: orderBy != 'Bemerkungen' }">Bemerkungen</th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'" colspan="2">Wechsel</th>
      </tr>
      <tr class="head" v-if="showCopy">
        <th v-if="Zugriffsrechte() !== 1 && (this.tableFunction === 'getInteressenten' || this.tableFunction === 'getAnträge')"><input type="checkbox" v-model="selectedColumns['Antragsdatum']" /></th>
        <th><input type="checkbox" v-model="selectedColumns['Anrede']" /></th>
        <th><input type="checkbox" v-model="selectedColumns['Nachname']" /></th>
        <th><input type="checkbox" v-model="selectedColumns['Vorname']" /></th>
        <th v-if="Zugriffsrechte() !== 1"><input type="checkbox" v-model="selectedColumns['Geburtsdatum']" /></th>
        <th v-if="Zugriffsrechte() !== 1"><input type="checkbox" v-model="selectedColumns['Alter']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && (Verband() === 0 || tableFunction == 'getFördermitglieder')"><input type="checkbox" v-model="selectedColumns['KV']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten' && this.tableFunction !== 'getAnträge'"><input type="checkbox" v-model="selectedColumns['Mitgliedsnummer']" /></th>
        <th v-if="Zugriffsrechte() !== 1"><input type="checkbox" v-model="selectedColumns['Adresse']" /></th>
        <th v-if="Zugriffsrechte() !== 1"><input type="checkbox" v-model="selectedColumns['Telefonnummer']" /></th>
        <th><input type="checkbox" v-model="selectedColumns['Mail']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten' && this.tableFunction !== 'getFördermitglieder'"><input type="checkbox" v-model="selectedColumns['Beitragskategorie']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['Mitgliedsbeitrag']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['Zahlungsintervall']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['bezahltAm']" /></th>
        <th v-if="Zugriffsrechte() === 0 && (this.tableFunction === 'getMitglieder' || this.tableFunction === 'getFördermitglieder')"></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['bezahltBis']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['Lastschrift']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['DatumLastschriftmandat']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['Kontoinhaber']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['IBAN']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['BIC']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['Kreditinstitut']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['Eintrittsdatum']" /></th>
        <th v-if="showAustritt && this.tableFunction !== 'getInteressenten'"><input type="checkbox" v-model="selectedColumns['Austrittsdatum']" /></th>
        <th v-if="Zugriffsrechte() !== 1"><input type="checkbox" v-model="selectedColumns['Bemerkungen']" /></th>
        <th v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'" colspan="2"><input type="checkbox" v-model="selectedColumns['Wechsel']" /></th>
      </tr>
      <tr v-for="(mitglied, index) in mitglieder" :key="mitglied">
        <td>
          {{index + 1}}
          <router-link v-if="Zugriffsrechte() !== 1" :to="{ path: '/mitglied/' + mitglied.mitgliederId }" class="stift">✎</router-link>
        </td>
        <td v-if="Zugriffsrechte() !== 1 && (this.tableFunction === 'getInteressenten' || this.tableFunction === 'getAnträge')">{{mitglied['Antragsdatum'] ? new Date(mitglied['Antragsdatum']).toLocaleDateString() : ''}}</td>
        <td>{{mitglied['Anrede']}}</td>
        <td>{{mitglied['Nachname']}}</td>
        <td>{{mitglied['Vorname']}}</td>
        <td v-if="Zugriffsrechte() !== 1">{{mitglied['Geburtsdatum'] ? new Date(mitglied['Geburtsdatum']).toLocaleDateString() : ''}}</td>
        <td v-if="Zugriffsrechte() !== 1">{{mitglied['Geburtsdatum'] ? alter(new Date(mitglied['Geburtsdatum'])) : ''}}</td>
        <td v-if="Zugriffsrechte() !== 1 && (Verband() === 0 || tableFunction == 'getFördermitglieder')">{{Kreisverbände[mitglied['Kreisverband']]}}</td>
        <td v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten' && this.tableFunction !== 'getAnträge'" class="monospace">{{mitglied.Mitgliedsnummer}}</td>
        <td v-if="Zugriffsrechte() !== 1">{{mitglied['Straße']}}<br>{{mitglied['Postleitzahl']}} {{mitglied['Ort']}}</td>
        <td v-if="Zugriffsrechte() !== 1">{{mitglied['Telefonnummer']}}</td>
        <td><a :href="'mailto:' + mitglied['Mail']">{{mitglied['Mail']}}</a></td>
        <td
          v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten' && this.tableFunction !== 'getFördermitglieder'"
          :class="{ fehlt: this.tableFunction === 'getFehlendDaten' && mitglied['Beitragskategorie'] === null}">
          {{Beitragskategorien(mitglied['Beitragskategorie'])}}</td>
        <td
          v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"
          :class="{ fehlt: this.tableFunction === 'getFehlendDaten' && mitglied['Mitgliedsbeitrag'] === null}">
          {{(mitglied['Mitgliedsbeitrag'] ? parseFloat(mitglied['Mitgliedsbeitrag']).toFixed(2).replace('.', ',') + ' €' : '')}}</td>
        <td
          v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"
          :class="{ fehlt: this.tableFunction === 'getFehlendDaten' && mitglied['Zahlungsintervall'] === null}">
          {{Intervalle(mitglied['Zahlungsintervall'])}}</td>
        <td v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">{{mitglied['bezahltAm'] ? new Date(mitglied['bezahltAm']).toLocaleDateString() : ''}}</td>
        <td v-if="Zugriffsrechte() === 0 && (this.tableFunction === 'getMitglieder' || this.tableFunction === 'getFördermitglieder')"><div v-if="mitglied['bezahltBis']" v-on:click="updateBezahlt(mitglied['mitgliederId'])" class="button magenta parallelogramm"><p>€</p></div></td>
        <td v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">{{mitglied['bezahltBis'] ? new Date(mitglied['bezahltBis']).toLocaleDateString() : ''}}</td>
        <td v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">{{mitglied['Lastschrift'] === null ? '' : (mitglied['Lastschrift'] ? 'ja' : 'nein')}}</td>
        <td
          v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">
          {{mitglied['DatumLastschriftmandat'] ? new Date(mitglied['DatumLastschriftmandat']).toLocaleDateString() : ''}}</td>
        <td
          v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"
          :class="{ fehlt: this.tableFunction === 'getFehlendDaten' && mitglied['Lastschrift'] && mitglied['Kontoinhaber'] === null}">
          {{mitglied['Kontoinhaber']}}</td>
        <td
          v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"
          class="monospace"
          :class="{ fehlt: this.tableFunction === 'getFehlendDaten' && mitglied['Lastschrift'] && mitglied['IBAN'] === null}">
          {{(mitglied['IBAN'] ? mitglied['IBAN'] : '').replace(/(.{4})/g,"$1&nbsp;")}}</td>
        <td
          v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'" class="monospace"
          :class="{ fehlt: this.tableFunction === 'getFehlendDaten' && mitglied['Lastschrift'] && mitglied['BIC'] === null}">
          {{mitglied['BIC']}}</td>
        <td
          v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'"
          :class="{ fehlt: this.tableFunction === 'getFehlendDaten' && mitglied['Lastschrift'] && mitglied['Kreditinstitut'] === null}">
          {{mitglied['Kreditinstitut']}}</td>
        <td v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">{{mitglied['Eintrittsdatum'] ? new Date(mitglied['Eintrittsdatum']).toLocaleDateString() : ''}}</td>
        <td v-if="Zugriffsrechte() !== 1 && showAustritt && this.tableFunction !== 'getInteressenten'">{{mitglied['Austrittsdatum'] ? new Date(mitglied['Austrittsdatum']).toLocaleDateString() : ''}}</td>
        <td v-if="Zugriffsrechte() !== 1" class="monospace">{{mitglied['Bemerkungen']}}</td>
        <td v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">{{mitglied['StatusWechsel'] > 0 ? WechselStatus(mitglied['StatusWechsel']) : ''}}</td>
        <td v-if="Zugriffsrechte() !== 1 && this.tableFunction !== 'getInteressenten'">{{mitglied['Wechselwunsch']}}</td>
      </tr>
      <!-- Mitglieder.Zahlungsintervall IS NULL
      OR (
        Mitglieder.Lastschrift = 1
        AND (
          Mitglieder.Kontoinhaber IS NULL
          OR Mitglieder.IBAN IS NULL
          OR Mitglieder.BIC IS NULL
          OR Mitglieder.Kreditinstitut IS NULL
        )
      ) -->
    </table>
  </span>
</template>

<script>
import axios from 'axios'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'Tabelle',
  mixins: [globalFunctions],
  props: {
    tableFunction: { type: String, default: '' },
    titel: { type: String, default: '' },
    reloadTime: { type: Number, default: 20 },
    showAustritt: { type: Boolean, default: false },
    KV: { type: String, default: null },
    showCopy: { type: Boolean, default: false }
  },

  data () {
    return {
      mitglieder: [],
      Kreisverbände: ['LV', 'DD', 'L', 'CH', 'DD + LV', 'L + LV', 'CH + LV', 'LV + DD + L + CH'],
      orderBy: null,
      selectedColumns: {
        Number: false,
        Anrede: true,
        Nachname: true,
        Vorname: true,
        Antragsdatum: false,
        Geburtsdatum: false,
        Alter: false,
        KV: false,
        Mitgliedsnummer: false,
        Adresse: false,
        Telefonnummer: false,
        Mail: true,
        Beitragskategorie: false,
        Mitgliedsbeitrag: false,
        Zahlungsintervall: false,
        bezahltAm: false,
        bezahltBis: false,
        Lastschrift: false,
        DatumLastschriftmandat: false,
        Kontoinhaber: false,
        IBAN: false,
        BIC: false,
        Kreditinstitut: false,
        Eintrittsdatum: false,
        Austrittsdatum: false,
        Bemerkungen: false,
        Wechsel: false
      }
    }
  },

  methods: {
    getData: function () {
      var data = {
        function: this.tableFunction,
        username: localStorage.username,
        password: localStorage.password
      }
      if (data.function.substring(0, 10) === 'getWechsel') {
        data.StatusWechsel = parseInt(data.function.substring(10, 11))
        data.function = 'getWechsel'
      }
      axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.mitglieder = response.data
        this.filtern()
        this.sortieren()
        if (window.getSelection()) {
          window.getSelection().removeAllRanges()
        } else if (document.selection) {
          document.selection.empty()
        }
      })
    },

    filtern: function () {
      if (this.KV === null) return null
      this.mitglieder = this.mitglieder.filter((a) => {
        return this.Kreisverbände[(a.Kreisverband - 1) % 3 + 1] === this.KV
      })
    },

    sortieren: function () {
      if (this.orderBy === null) return null
      this.mitglieder.sort((a, b) => {
        if (this.orderBy === 'Geburtstag') {
          const gebA = new Date('1970' + a.Geburtsdatum.substring(4))
          const gebB = new Date('1970' + b.Geburtsdatum.substring(4))
          if (gebA === gebB) return 0
          if (gebA < gebB) return -1
          return 1
        }
        if (typeof a[this.orderBy] === 'string') return a[this.orderBy].localeCompare(b[this.orderBy])
        if (a[this.orderBy] === b[this.orderBy]) return 0
        if (a[this.orderBy] < b[this.orderBy]) return -1
        return 1
      })
    },

    updateBezahlt: function (person) {
      var data = {
        function: 'updateBezahlt',
        mitgliederId: person,
        username: localStorage.username,
        password: localStorage.password
      }
      axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
        this.getData()
      })
    },

    alter: function (geb) {
      var age = 0
      var jetzt = new Date()
      jetzt.setHours(24)
      jetzt.setMinutes(0)
      jetzt.setSeconds(0)
      while (geb.setFullYear(geb.getFullYear() + 1) < jetzt) {
        age++
      }
      return age
    },

    copy: function () {
      var text = ''

      // Kopfzeile kopieren
      if (this.selectedColumns.Number) text += '\t'
      if (this.selectedColumns.Anrede) text += 'Anrede\t'
      if (this.selectedColumns.Nachname) text += 'Nachname\t'
      if (this.selectedColumns.Vorname) text += 'Vorname\t'
      if (this.selectedColumns.Antragsdatum) text += 'Antragsdatum\t'
      if (this.selectedColumns.Geburtsdatum) text += 'Geburtsdatum\t'
      if (this.selectedColumns.Alter) text += 'Alter\t'
      if (this.selectedColumns.KV && (this.Verband() === 0 || this.tableFunction === 'getFördermitglieder')) text += 'Kreisverband\t'
      if (this.selectedColumns.Mitgliedsnummer && this.tableFunction !== 'getInteressenten') text += 'Mitgliedsnummer\t'
      if (this.selectedColumns.Adresse) text += 'Straße\tPostleitzahl\tOrt\t'
      if (this.selectedColumns.Telefonnummer) text += 'Telefonnummer\t'
      if (this.selectedColumns.Mail) text += 'E-Mail\t'
      if (this.selectedColumns.Beitragskategorie && this.tableFunction !== 'getInteressenten' && this.tableFunction !== 'getFördermitglieder') text += 'Beitragskategorie\t'
      if (this.selectedColumns.Mitgliedsbeitrag && this.tableFunction !== 'getInteressenten') text += 'Mitgliedsbeitrag\t'
      if (this.selectedColumns.Zahlungsintervall && this.tableFunction !== 'getInteressenten') text += 'Zahlungsintervall\t'
      if (this.selectedColumns.bezahltAm && this.tableFunction !== 'getInteressenten') text += 'bezahlt am\t'
      if (this.selectedColumns.bezahltBis && this.tableFunction !== 'getInteressenten') text += 'bezahlt bis\t'
      if (this.selectedColumns.Lastschrift && this.tableFunction !== 'getInteressenten') text += 'Lastschrift\t'
      if (this.selectedColumns.DatumLastschriftmandat && this.tableFunction !== 'getInteressenten') text += 'Datum Lastschriftmandat\t'
      if (this.selectedColumns.Kontoinhaber && this.tableFunction !== 'getInteressenten') text += 'Kontoinhaber\t'
      if (this.selectedColumns.IBAN && this.tableFunction !== 'getInteressenten') text += 'IBAN\t'
      if (this.selectedColumns.BIC && this.tableFunction !== 'getInteressenten') text += 'BIC\t'
      if (this.selectedColumns.Kreditinstitut && this.tableFunction !== 'getInteressenten') text += 'Kreditinstitut\t'
      if (this.selectedColumns.Eintrittsdatum && this.tableFunction !== 'getInteressenten') text += 'Eintrittsdatum\t'
      if (this.selectedColumns.Austrittsdatum && this.tableFunction !== 'getInteressenten' && this.showAustritt) text += 'Austrittsdatum\t'
      if (this.selectedColumns.Bemerkungen) text += 'Bemerkungen\t'
      if (this.selectedColumns.Wechsel && this.tableFunction !== 'getInteressenten') text += 'Wechsel\t'
      text = text.substring(0, text.length - 1) + '\n'

      // Zeilen
      this.mitglieder.forEach((mitglied, index) => {
        if (this.selectedColumns.Number) text += (index + 1) + '\t'
        if (this.selectedColumns.Anrede) text += this.notNull(mitglied.Anrede) + '\t'
        if (this.selectedColumns.Nachname) text += this.notNull(mitglied.Nachname) + '\t'
        if (this.selectedColumns.Vorname) text += this.notNull(mitglied.Vorname) + '\t'
        if (this.selectedColumns.Antragsdatum) text += new Date(mitglied.Antragsdatum).toLocaleDateString() + '\t'
        if (this.selectedColumns.Geburtsdatum) text += new Date(mitglied.Geburtsdatum).toLocaleDateString() + '\t'
        if (this.selectedColumns.Alter) text += this.alter(new Date(mitglied.Geburtsdatum)) + '\t'
        if (this.selectedColumns.KV && (this.Verband() === 0 || this.tableFunction === 'getFördermitglieder')) text += this.Kreisverbände[mitglied.Kreisverband] + '\t'
        if (this.selectedColumns.Mitgliedsnummer && this.tableFunction !== 'getInteressenten') text += this.notNull(mitglied.Mitgliedsnummer) + '\t'
        if (this.selectedColumns.Adresse) text += this.notNull(mitglied.Straße) + '\t' + this.notNull(mitglied.Postleitzahl) + '\t' + this.notNull(mitglied.Ort) + '\t'
        if (this.selectedColumns.Telefonnummer) text += this.notNull(mitglied.Telefonnummer) + '\t'
        if (this.selectedColumns.Mail) text += this.notNull(mitglied.Mail) + '\t'
        if (this.selectedColumns.Beitragskategorie && this.tableFunction !== 'getInteressenten' && this.tableFunction !== 'getFördermitglieder') text += this.Beitragskategorien(mitglied.Beitragskategorie) + '\t'
        if (this.selectedColumns.Mitgliedsbeitrag && this.tableFunction !== 'getInteressenten') text += parseFloat(mitglied.Mitgliedsbeitrag).toFixed(2).replace('.', ',') + ' €' + '\t'
        if (this.selectedColumns.Zahlungsintervall && this.tableFunction !== 'getInteressenten') text += this.Intervalle(mitglied.Zahlungsintervall) + '\t'
        if (this.selectedColumns.bezahltAm && this.tableFunction !== 'getInteressenten') text += new Date(mitglied.bezahltAm).toLocaleDateString() + '\t'
        if (this.selectedColumns.bezahltBis && this.tableFunction !== 'getInteressenten') text += new Date(mitglied.bezahltBis).toLocaleDateString() + '\t'
        if (this.selectedColumns.Lastschrift && this.tableFunction !== 'getInteressenten') text += (mitglied.Lastschrift === null ? '' : (mitglied.Lastschrift ? 'ja' : 'nein')) + '\t'
        if (this.selectedColumns.DatumLastschriftmandat && this.tableFunction !== 'getInteressenten') text += (mitglied.DatumLastschriftmandat ? new Date(mitglied.DatumLastschriftmandat).toLocaleDateString() : '') + '\t'
        if (this.selectedColumns.Kontoinhaber && this.tableFunction !== 'getInteressenten') text += this.notNull(mitglied.Kontoinhaber) + '\t'
        if (this.selectedColumns.IBAN && this.tableFunction !== 'getInteressenten') text += this.notNull(mitglied.IBAN).replace(/(.{4})/g, '$1 ') + '\t'
        if (this.selectedColumns.BIC && this.tableFunction !== 'getInteressenten') text += this.notNull(mitglied.BIC) + '\t'
        if (this.selectedColumns.Kreditinstitut && this.tableFunction !== 'getInteressenten') text += this.notNull(mitglied.Kreditinstitut) + '\t'
        if (this.selectedColumns.Eintrittsdatum && this.tableFunction !== 'getInteressenten') text += (mitglied.Eintrittsdatum ? new Date(mitglied.Eintrittsdatum).toLocaleDateString() : '') + '\t'
        if (this.selectedColumns.Austrittsdatum && this.tableFunction !== 'getInteressenten' && this.showAustritt) text += (mitglied.Austrittsdatum ? new Date(mitglied.Austrittsdatum).toLocaleDateString() : '') + '\t'
        if (this.selectedColumns.Bemerkungen) text += this.notNull(mitglied.Bemerkungen) + '\t'
        if (this.selectedColumns.Wechsel && this.tableFunction !== 'getInteressenten') text += (mitglied.StatusWechsel > 0 ? this.WechselStatus(mitglied.StatusWechsel) : '') + ' ' + this.notNull(mitglied.Wechselwunsch) + '\t'

        text = text.substring(0, text.length - 1) + '\n'
      })
      navigator.clipboard.writeText(text.substring(0, text.length - 1))

      const sign = this.$refs.copySign.classList
      sign.add('active')
      setTimeout(function () { sign.remove('active') }, 1000)
    },

    notNull: function (text) {
      if (text) return text
      return ''
    }
  },

  mounted: function () {
    this.getData()
    setInterval(this.getData, this.reloadTime * 1000)
  },

  watch: {
    $route: function (to, from) {
      this.getData()
    }
  }
}
</script>

<style scoped>
h2 {
  margin: 0;
  color: var(--magenta);
}

#copySign {
  height: 2em;
  fill: var(--weiß);
  transition: fill 0.25s;
}

#copySign.active {
  fill: var(--magenta);
}

table {
  margin-bottom: 2rem;
}

.stift {
  font-size: 1.5rem;
  color: var(--blau);
}

.stift:hover, a:hover {
  color: var(--magenta);
}

th.selected {
  text-decoration: underline;
}

th.unselected {
  cursor: s-resize;
}

td {
  height: 2em;
}

td.monospace {
  font-variant-numeric: tabular-nums;
}

.parallelogramm {
  /* transform: skewX(-15deg); */
  font-size: 1.5em;
  padding: 5px;
  text-align: center;
  border-radius: 1em;
}

.parallelogramm p {
  /* transform: skewX(15deg); */
  margin: 0;
}

.button {
  cursor: pointer;
}

.parallelogramm.magenta {
  color: var(--weiß);
  background: var(--magenta);
}

.fehlt {
  background: var(--magentaHell);
}
</style>
